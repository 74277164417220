html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;

  #root {
    width: 100%;
    height: 100%;
    background-color: #382f50;
  }
}